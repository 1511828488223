import { isMobile } from '../../../../site/js/utils/breakpoint'
import { initRequest } from '../../../../site/js/utils/request'

export class VideoStreaming {
  protected wrapperElement: HTMLDivElement

  constructor(element: HTMLDivElement) {
    this.wrapperElement = element
    this.init()
  }

  private init(): void {
    initRequest()
    this.initVideo()
  }

  private initVideo(): void {
    const video = this.wrapperElement.querySelector('video')
    const { videourl: src, posterimageurl: poster, mobileVideoDisabled, autoplay, videopreload: videoPreload } = this.wrapperElement.dataset

    if (!video.src) {
      video.src = src
    }

    if (!video.poster) {
      video.poster = poster
    }

    const autopreload = videoPreload === 'true' ? true : false
    if (!autopreload) {
      video.autoplay = mobileVideoDisabled === 'true' && isMobile ? false : autoplay === 'true'
      video.preload = 'none'
    }
  }
}
